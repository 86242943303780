import React from 'react';

import { contactExists, userExists } from '../api';
import { buildUrlFromPath } from './paths';

const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
export const getUserOrContact = async ({
   username,
   setLoginErrorMessage,
}: {
   username: string;
   setLoginErrorMessage: React.Dispatch<React.SetStateAction<string>>;
}) => {
   let userAccountExists = false;
   setLoginErrorMessage('');
   if (emailRegex.test(username)) {
      try {
         const { isUser, message } = await userExists(username);
         isUser
            ? (userAccountExists = isUser)
            : setLoginErrorMessage(message ?? 'Unknown error occurred. try again');
      } catch (error) {
         setLoginErrorMessage('Unknown error occurred. Contact support for assistance');
      }
   } else if (!emailRegex.test(username)) {
      const { isContact, message } = await contactExists(username);
      isContact
         ? (userAccountExists = isContact)
         : setLoginErrorMessage(message ?? 'Unknown error occurred. try again');
   } else {
      setLoginErrorMessage('Enter a phone number or Email address');
   }
   return userAccountExists;
};

function stringToColor(string: string) {
   let hash = 0;
   let i;

   /* eslint-disable no-bitwise */
   for (i = 0; i < string.length; i += 1) {
      hash = string.charCodeAt(i) + ((hash << 5) - hash);
   }

   let color = '#';

   for (i = 0; i < 3; i += 1) {
      const value = (hash >> (i * 8)) & 0xff;
      color += `00${value.toString(16)}`.slice(-2);
   }
   /* eslint-enable no-bitwise */

   return color;
}

export const stringAvatar = (name: string, image?: string) => {
   return image
      ? {
           src: buildUrlFromPath(image),
        }
      : {
           sx: {
              bgcolor: stringToColor(name),
           },
           children: `${name.split(' ')[0][0]}${name.split(' ')[1][0]}`,
        };
};

export const convertStringToTitleCase = (value: string) => {
   const sentence = value.toLowerCase().split(' ');
   for (let i = 0; i < sentence.length; i++) {
      sentence[i] = sentence[i][0].toUpperCase() + sentence[i].slice(1);
   }
   return sentence.join(' ');
};
