import { ContextProviderType } from '@titl-all/shared';
import React, { createContext, useState } from 'react';

export const MenuContext = createContext<{
   menuContent: string;
   setMenuContent: React.Dispatch<React.SetStateAction<string>>;
}>({
   menuContent: '',
   setMenuContent: () => undefined,
});

export const MenuContextProvider: React.FC<ContextProviderType> = ({
   children,
}: ContextProviderType) => {
   const [menuContent, setMenuContent] = useState<string>('');
   return (
      <MenuContext.Provider
         value={{
            menuContent,
            setMenuContent,
         }}
      >
         {children}
      </MenuContext.Provider>
   );
};
