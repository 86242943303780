import React, { FC, Fragment, Suspense, useContext, useEffect } from 'react';
import { Navigate, Outlet, Route, Routes, useLocation } from 'react-router-dom';

import { Layout, LoadingSpinner, UnAuthorizedLayout } from './components';
import { LayoutContext } from './context';
import { useUser } from './hooks';
import useUserAdmin from './hooks/useUserAdmin';
import {
   ADD_ANOTHER_PAYMENT_ROUTE_PATH,
   ADD_EXISTING_TENANT_ROUTE_PATH,
   ADD_NEW_TENANT_ROUTE_PATH,
   ADD_PAYMENT_ROUTE_PATH,
   ADD_PROPERTY_ROUTE_PATH,
   ADD_PROPERTY_UNIT_ROUTE_PATH,
   ADD_PROPERTY_UNITS_ROUTE_PATH,
   ADD_TENANT_ONBOARD_ROUTE_PATH,
   ADD_TENANT_ROUTE_PATH,
   ADD_UNIT_ONBOARD_ROUTE_PATH,
   ADMIN_DASHBOARD_ROUTE_PATH,
   ADMIN_PROPERTIES_ROUTE_PATH,
   ALL_UNITS_ROUTE_PATH,
   BALANCE_ROUTE_PATH,
   COLLECTION_ROUTE_PATH,
   COLLECTIONS_ROUTE_PATH,
   DASHBOARD_ROUTE_PATH,
   EDIT_PROPERTY_ROUTE_PATH,
   EDIT_PROPERTY_UNIT_ROUTE_PATH,
   EDIT_TENANT_PROFILE_ROUTE_PATH,
   FORGOT_PASSWORD_ROUTE_PATH,
   IMPORT_PAYMENT_ROUTE_PATH,
   IMPORT_PROPERTY_UNITS_ROUTE_PATH,
   IMPORT_TENANT_ROUTE_PATH,
   INDEX_ROUTE_PATH,
   INVOICE_DETAILS_ROUTE_PATH,
   INVOICES_ROUTE_PATH,
   LOGIN_ROUTE_PATH,
   OCCUPANCIES_ROUTE_PATH,
   PAYMENTS_ROUTE_PATH,
   PROPERTIES_ROUTE_PATH,
   PROPERTY_BALANCE_ROUTE_PATH,
   PROPERTY_COLLECTIONS_ROUTE_PATH,
   PROPERTY_INVOICES_ROUTE_PATH,
   PROPERTY_OCCUPANCY_ROUTE_PATH,
   PROPERTY_TENANTS_ROUTE_PATH,
   PROPERTY_UNIT_PROFILE_ROUTE_PATH,
   PROPERTY_UNITS_IMAGES_UPLOAD_ROUTE_PATH,
   PROPERTY_UNITS_ROUTE_PATH,
   REQUESTS_ROUTE_PATH,
   SIGNUP_MANAGER_ROUTE_PATH,
   SIGNUP_OWNER_ROUTE_PATH,
   SIGNUP_ROUTE_PATH,
   SINGLE_PROPERTY_DASHBOARD_ROUTE_PATH,
   TENANT_AGREEMENT_ROUTE_PATH,
   TENANT_DOCUMENTS_ROUTE_PATH,
   TENANT_PROFILE_ROUTE_PATH,
   TENANTS_ROUTE_PATH,
   TRANSACTION_DETAILS_ROUTE_PATH,
   TRANSACTIONS_ROUTE_PATH,
   UPDATE_PASSWORD_ROUTE_PATH,
   VERIFY_CODE_ROUTE_PATH,
   NOTIFICATION_ROUTE_PATH,
   TENANT_RENEW_AGREEMENT_ROUTE_PATH,
} from './route-paths';
import { setUserProperties } from 'firebase/analytics';
import { analytics } from './firebase';

export const lazy: typeof React.lazy = (importer) => {
   const retryImport = async () => {
      try {
         return await importer();
      } catch (error) {
         // retry 5 times with 1 second delay
         for (let i = 0; i < 5; i++) {
            await new Promise((resolve) => setTimeout(resolve, 1000 * 2 ** i));
            try {
               return await importer();
            } catch (e) {
               console.log('retrying import');
            }
         }
         throw error;
      }
   };
   return React.lazy(retryImport);
};

// user pages
const LoginPage = lazy(() => import('./pages/user/Login'));
const SignUpPage = lazy(() => import('./pages/user/Signup'));
const SignUpManagerPage = lazy(() => import('./pages/user/SignupManager'));
const SignUPOwnerPage = lazy(() => import('./pages/user/SignupOwner'));
const UpdatePasswordPage = lazy(() => import('./pages/user/UpdatePassword'));
const ForgotPasswordPage = lazy(() => import('./pages/user/ForgotPassword'));
const CodeVerificationPage = lazy(() => import('./pages/user/CodeVerification'));

// Home Pages
const DashboardPage = lazy(() => import('./pages/Dashboard/Dashboard'));
const SinglePropertyDashboardPage = lazy(() => import('./pages/Dashboard/SinglePropertyDashboard'));
const AdminDashboardPage = lazy(() => import('./pages/Dashboard/AdminDashboard'));
const NotificationPage = lazy(() => import('./pages/Dashboard/Notification'));

// Properties Pages
const PropertiesPage = lazy(() => import('./pages/Properties'));
const AddPropertyPage = lazy(() => import('./pages/Properties/Property'));
const EditPropertyPage = lazy(() => import('./pages/Properties/EditProperty'));
const AdminPropertiesPage = lazy(() => import('./pages/Properties/AdminProperties'));

// Tenants Pages
const TenantsPage = lazy(() => import('./pages/Tenants'));
const SinglePropertyTenantsPage = lazy(() => import('./pages/Tenants/SinglePropertyTenants'));
const AddTenantPage = lazy(() => import('./pages/Tenants/AddTenant'));
const AddExistingTenantPage = lazy(() => import('./pages/Tenants/AddExistingTenant'));
const EditTenantPage = lazy(() => import('./pages/Tenants/EditTenant'));
const ImportTenantsPage = lazy(() => import('./pages/Tenants/ImportTenants'));
const TenantProfilePage = lazy(() => import('./pages/Tenants/TenantProfile'));
const TenantAgreementPage = lazy(() => import('./pages/Tenants/TenantAgreement'));
const TenantDocumentsPage = lazy(() => import('./pages/Tenants/TenantDocuments'));

// Units Pages
const UnitsPages = lazy(() => import('./pages/Units'));
const SinglePropertyUnitsPages = lazy(() => import('./pages/Units/SinglePropertyUnits'));
const UnitProfilePage = lazy(() => import('./pages/Units/UnitProfile'));
const ImportUnitsPage = lazy(() => import('./pages/Units/ImportUnits'));
const UploadUnitImagesPage = lazy(() => import('./pages/Units/ImagesUpload'));
const AddPropertyUnitPage = lazy(() => import('./pages/Units/AddPropertyUnit'));
const EditPropertyUnitPage = lazy(() => import('./pages/Units/EditPropertyUnit'));

// Transactions Pages
const TransactionsPage = lazy(() => import('./pages/Transactions'));
const TransactionDetailsPage = lazy(() => import('./pages/Transactions/transactionDetails'));
const InvoicesPage = lazy(() => import('./pages/Transactions/Invoices'));
const InvoiceDetailsPage = lazy(() => import('./pages/Transactions/invoiceDetails'));

// Payments Pages
const PaymentsPage = lazy(() => import('./pages/Payments'));
const AddPaymentPage = lazy(() => import('./pages/Payments/AddPayment'));
const AddTenancyPaymentPage = lazy(() => import('./pages/Payments/AddTenancyPayment'));
const ImportPaymentPage = lazy(() => import('./pages/Payments/ImportPayments'));

// Occupancy Pages
const OccupancyPage = lazy(() => import('./pages/Occupancy'));

// Collections Pages
const CollectionsPage = lazy(() => import('./pages/collections'));
const CollectionPage = lazy(() => import('./pages/collections/collection'));

// Balances Pages
const BalancesPage = lazy(() => import('./pages/Balance'));

// Requests Pages
const RequestsPages = lazy(() => import('./pages/Requests'));

// Onboard Pages
const AddTenantsOnboardPage = lazy(() => import('./pages/onboard/OnboardAddTenants'));
const AddUnitOnboardPage = lazy(() => import('./pages/onboard/OnboardAddUnit'));

interface IPrivateRouteProps {
   children?: JSX.Element;
}
const PrivateRoute: FC<IPrivateRouteProps> = ({ children }) => {
   const [user] = useUser();
   const location = useLocation();
   if (!user) {
      return <Navigate to={LOGIN_ROUTE_PATH} replace state={{ from: location }} />;
   }
   return (
      children || (
         <Layout>
            <Outlet />
         </Layout>
      )
   );
};

const AdminPrivateRoute: FC<{ children: JSX.Element }> = ({ children }) => {
   const location = useLocation();
   const isUserAdmin = useUserAdmin();
   const { setAdminLayOutValue, adminLayOutValue } = useContext(LayoutContext);
   if (!isUserAdmin) {
      return <Navigate to={DASHBOARD_ROUTE_PATH} replace state={{ from: location }} />;
   }
   !adminLayOutValue && setAdminLayOutValue(true);
   return children;
};

const RoutePaths: FC = () => {
   const [user] = useUser();
   useEffect(() => {
      if (user) {
         setUserProperties(analytics, user as any);
      }
   }, [user]);
   return (
      <Fragment>
         {!user ? (
            <Suspense
               fallback={
                  <UnAuthorizedLayout>
                     <LoadingSpinner />
                  </UnAuthorizedLayout>
               }
            >
               <Routes>
                  <Route path={INDEX_ROUTE_PATH} element={<Navigate to={LOGIN_ROUTE_PATH} />} />
                  <Route path={LOGIN_ROUTE_PATH} element={<LoginPage />} />
                  <Route path={SIGNUP_ROUTE_PATH} element={<SignUpPage />} />
                  <Route path={SIGNUP_OWNER_ROUTE_PATH} element={<SignUPOwnerPage />} />
                  <Route path={SIGNUP_MANAGER_ROUTE_PATH} element={<SignUpManagerPage />} />
                  <Route path={FORGOT_PASSWORD_ROUTE_PATH} element={<ForgotPasswordPage />} />
                  <Route path={VERIFY_CODE_ROUTE_PATH} element={<CodeVerificationPage />} />
                  <Route path={UPDATE_PASSWORD_ROUTE_PATH} element={<UpdatePasswordPage />} />
                  <Route path={NOTIFICATION_ROUTE_PATH} element={<NotificationPage />} />
               </Routes>
            </Suspense>
         ) : (
            <Suspense
               fallback={
                  <Layout>
                     <LoadingSpinner />
                  </Layout>
               }
            >
               <Routes>
                  <Route element={<PrivateRoute />}>
                     <Route
                        path={INDEX_ROUTE_PATH}
                        element={<Navigate to={DASHBOARD_ROUTE_PATH} />}
                     />
                     <Route path={DASHBOARD_ROUTE_PATH} element={<DashboardPage />} />
                     <Route path={NOTIFICATION_ROUTE_PATH} element={<NotificationPage />} />
                     <Route
                        path={SINGLE_PROPERTY_DASHBOARD_ROUTE_PATH}
                        element={<SinglePropertyDashboardPage />}
                     />
                     <Route path={COLLECTIONS_ROUTE_PATH} element={<CollectionsPage />} />
                     <Route path={COLLECTION_ROUTE_PATH} element={<CollectionPage />} />
                     <Route path={PROPERTY_COLLECTIONS_ROUTE_PATH} element={<CollectionsPage />} />
                     <Route path={PAYMENTS_ROUTE_PATH} element={<PaymentsPage />} />
                     <Route path={ADD_PAYMENT_ROUTE_PATH} element={<AddPaymentPage />} />
                     <Route
                        path={ADD_ANOTHER_PAYMENT_ROUTE_PATH}
                        element={<AddTenancyPaymentPage />}
                     />
                     <Route path={IMPORT_PAYMENT_ROUTE_PATH} element={<ImportPaymentPage />} />
                     <Route path={PROPERTIES_ROUTE_PATH} element={<PropertiesPage />} />
                     <Route path={ADD_PROPERTY_ROUTE_PATH} element={<AddPropertyPage />} />
                     <Route path={EDIT_PROPERTY_ROUTE_PATH} element={<EditPropertyPage />} />
                     <Route path={ALL_UNITS_ROUTE_PATH} element={<UnitsPages />} />
                     <Route
                        path={PROPERTY_UNITS_ROUTE_PATH}
                        element={<SinglePropertyUnitsPages />}
                     />
                     <Route
                        path={ADD_PROPERTY_UNITS_ROUTE_PATH}
                        element={<AddPropertyUnitPage />}
                     />
                     <Route path={ADD_PROPERTY_UNIT_ROUTE_PATH} element={<AddPropertyUnitPage />} />
                     <Route
                        path={EDIT_PROPERTY_UNIT_ROUTE_PATH}
                        element={<EditPropertyUnitPage />}
                     />
                     <Route path={PROPERTY_UNIT_PROFILE_ROUTE_PATH} element={<UnitProfilePage />} />
                     <Route path={IMPORT_PROPERTY_UNITS_ROUTE_PATH} element={<ImportUnitsPage />} />
                     <Route
                        path={PROPERTY_UNITS_IMAGES_UPLOAD_ROUTE_PATH}
                        element={<UploadUnitImagesPage />}
                     />
                     <Route path={REQUESTS_ROUTE_PATH} element={<RequestsPages />} />
                     <Route path={TENANTS_ROUTE_PATH} element={<TenantsPage />} />
                     <Route
                        path={PROPERTY_TENANTS_ROUTE_PATH}
                        element={<SinglePropertyTenantsPage />}
                     />
                     <Route path={ADD_TENANT_ROUTE_PATH} element={<AddTenantPage />} />
                     <Route
                        path={ADD_EXISTING_TENANT_ROUTE_PATH}
                        element={<AddExistingTenantPage />}
                     />
                     <Route path={ADD_NEW_TENANT_ROUTE_PATH} element={<AddExistingTenantPage />} />
                     <Route path={IMPORT_TENANT_ROUTE_PATH} element={<ImportTenantsPage />} />
                     <Route path={TENANT_PROFILE_ROUTE_PATH} element={<TenantProfilePage />} />
                     <Route path={TENANT_AGREEMENT_ROUTE_PATH} element={<TenantAgreementPage />} />
                     <Route
                        path={TENANT_RENEW_AGREEMENT_ROUTE_PATH}
                        element={<TenantAgreementPage />}
                     />

                     <Route path={TENANT_DOCUMENTS_ROUTE_PATH} element={<TenantDocumentsPage />} />
                     <Route path={EDIT_TENANT_PROFILE_ROUTE_PATH} element={<EditTenantPage />} />
                     <Route path={TRANSACTIONS_ROUTE_PATH} element={<TransactionsPage />} />
                     <Route
                        path={TRANSACTION_DETAILS_ROUTE_PATH}
                        element={<TransactionDetailsPage />}
                     />
                     <Route path={OCCUPANCIES_ROUTE_PATH} element={<OccupancyPage />} />
                     <Route path={PROPERTY_OCCUPANCY_ROUTE_PATH} element={<OccupancyPage />} />
                     <Route path={BALANCE_ROUTE_PATH} element={<BalancesPage />} />
                     <Route path={PROPERTY_BALANCE_ROUTE_PATH} element={<BalancesPage />} />
                     <Route path={INVOICES_ROUTE_PATH} element={<InvoicesPage />} />
                     <Route path={PROPERTY_INVOICES_ROUTE_PATH} element={<InvoicesPage />} />
                     <Route path={INVOICE_DETAILS_ROUTE_PATH} element={<InvoiceDetailsPage />} />
                     <Route path={ADD_UNIT_ONBOARD_ROUTE_PATH} element={<AddUnitOnboardPage />} />
                     <Route
                        path={ADD_TENANT_ONBOARD_ROUTE_PATH}
                        element={<AddTenantsOnboardPage />}
                     />
                     {/* Admin routes */}
                     <Route
                        path={ADMIN_DASHBOARD_ROUTE_PATH}
                        element={<Navigate to={ADMIN_PROPERTIES_ROUTE_PATH} />}
                     />
                     <Route
                        path={ADMIN_PROPERTIES_ROUTE_PATH}
                        element={
                           <AdminPrivateRoute>
                              <AdminPropertiesPage />
                           </AdminPrivateRoute>
                        }
                     />
                     <Route path="*" element={<Navigate to={INDEX_ROUTE_PATH} />} />
                  </Route>
               </Routes>
            </Suspense>
         )}
      </Fragment>
   );
};
export default RoutePaths;
