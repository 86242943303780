import classnames from 'classnames';
import React from 'react';
import { SVGComponent } from './types';

interface Props {
   /** The fill color of the icon. Uses Tailwind colors (e.g.: 'green-500') */
   color?: string;
   /** Text a screenreader will read aloud when it gets to the icon */
   label?: string;
   /** The width and height of the icon */
   size?: number;
   /** The SVG we're rendering */
   svg: SVGComponent;
   className?: string; // Add className prop
}

const Icon: React.FC<Props> = ({ svg: Svg, size = 20, color, label, className }) => (
   <Svg
      width={size}
      height={size}
      className={classnames(className, { [`text-${color}`]: color })}
      fill={color}
      aria-label={label}
   />
);

export default Icon;
