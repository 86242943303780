import { green, grey } from '@mui/material/colors';
import { alpha, createTheme, darken } from '@mui/material/styles';

const theme = createTheme({
   palette: {
      primary: {
         main: green[500],
      },
      secondary: { main: '#363c49' },
      error: {
         main: '#E44D3ACC',
      },
   },
});
const { palette } = theme;
export const defaultTheme = createTheme({
   ...theme,
   typography: {
      fontFamily: ['Inter', 'Nunito', 'Roboto', 'Helvetica Neue', 'Arial', 'sans-serif'].join(','),
   },
   components: {
      MuiTextField: {
         defaultProps: {
            variant: 'outlined',
            size: 'medium',
            fullWidth: true,
         },
      },
      MuiButton: {
         styleOverrides: {
            root: {
               textTransform: 'none',
               color: 'white',
               borderRadius: '8px',
               background: 'rgba(0, 176, 83, 0.8)',
               width: '319px',
               height: '49px',
               '&:hover': {
                  backgroundColor: '#35C37D',
               },
            },
         },
      },
      MuiOutlinedInput: {
         styleOverrides: {
            notchedOutline: {
               borderRadius: 8,
               border: '1px solid rgba(31, 84, 96, 0.2)',
               boxSizing: 'border-box',
            },
            input: {
               height: 'auto',
            },
         },
      },
      MuiInputBase: {
         styleOverrides: {
            input: {
               fontSize: 14,
            },
         },
      },
      MuiTypography: {
         styleOverrides: {
            h1: {
               fontStyle: 'normal',
               fontWeight: '700',
               fontSize: '60px',
               lineHeight: '120%',
               color: '#002616',
            },
            h4: {
               fontStyle: 'normal',
               fontWeight: '500',
               fontSize: '17px',
               lineHeight: '20px',
               color: '#879EA4',
            },
         },
      },
   },
});
