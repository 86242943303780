import classnames from 'classnames';
import React from 'react';
import Icon from './Icon';
import { SVGComponent } from './types';

interface Props extends Pick<React.HTMLProps<HTMLButtonElement>, 'onClick' | 'disabled'> {
   icon: SVGComponent;
   label: string;
   iconSize?: number;
   active?: boolean;
   className?: string; // Added className prop
}

const StackedIconButton: React.FC<Props> = ({
   icon,
   iconSize = 20,
   label,
   onClick,
   disabled,
   active,
   className,
}) => {
   return (
      <button
         className={classnames(
            'relative flex flex-col items-center p-2 bg-inherit border-none cursor-pointer',
            className, // Add className prop to the classNames function
            { current: active }, // Conditionally add 'current' class based on active prop
         )}
         onClick={onClick}
         disabled={disabled}
      >
         <Icon svg={icon} size={iconSize} className="icon" />
         <span className="block text-white label">{label}</span>
      </button>
   );
};

export default StackedIconButton;
