import { Autocomplete, TextField } from '@mui/material';
import React, { forwardRef } from 'react';
import useSWR from 'swr';

import { GET_PROPERTIES_ENDPOINT_PATH } from '../endpoint-paths';
import { PropertyDetails } from '../types';
interface PropertyDropdownFieldProps {
   label?: string;
   onChangeProperty: (option: any) => void;
}

export const PropertyDropdownField = forwardRef<HTMLDivElement, PropertyDropdownFieldProps>(
   function PropertyDropdownField({ ...rest }, ref) {
      const { label = 'Filter by Property', onChangeProperty } = rest;
      const { data: properties } = useSWR<PropertyDetails[]>(GET_PROPERTIES_ENDPOINT_PATH);
      return (
         <>
            {properties && (
               <Autocomplete
                  disablePortal
                  id="combo-box-demo"
                  options={[{ id: 0, name: 'All' }, ...properties]}
                  defaultValue={{ id: 0, name: 'All' }}
                  isOptionEqualToValue={(option, value) => option.id === value.id}
                  onChange={(e, option) => {
                     if (option) {
                        onChangeProperty(option);
                     }
                  }}
                  getOptionLabel={(option) => option.name}
                  renderOption={(props, option) => (
                     <li value={option.id} {...props} key={option.id}>
                        {option.name}
                     </li>
                  )}
                  renderInput={(params) => <TextField {...params} label={label} ref={ref} />}
               />
            )}
         </>
      );
   },
);
export default PropertyDropdownField;
