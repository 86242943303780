import { ContextProviderType, User } from '@titl-all/shared';
import React, { useState } from 'react';

import { UserContext } from './UserContext';

export const UserContextProvider: React.FC<ContextProviderType> = ({
   children,
}: ContextProviderType) => {
   const [user, setUser] = useState<User | null | undefined>();
   const [isSet, setIsSet] = useState<boolean>(false);
   return (
      <UserContext.Provider
         value={{
            user,
            setUser,
            isSet,
            setIsSet,
         }}
      >
         {children}
      </UserContext.Provider>
   );
};
