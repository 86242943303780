import { ArrowBackIos, ArrowForwardIos, Dashboard, Home, Logout } from '@mui/icons-material';
import { Button, IconButton } from '@mui/material';
import React, { FC, useContext, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { ReactComponent as TitlLogo } from '../../assets/Titl_logo.svg';
import { LayoutContext } from '../../context';
import {
   ADMIN_DASHBOARD_ROUTE_PATH,
   ADMIN_PROPERTIES_ROUTE_PATH,
   DASHBOARD_ROUTE_PATH,
   LOGIN_ROUTE_PATH,
} from '../../route-paths';
import SideBarItem from './SideBarItem';
interface SideBarProps {
   setCollapseSideBar: React.Dispatch<React.SetStateAction<boolean>>;
}
const SideBar: FC<SideBarProps> = ({ setCollapseSideBar }) => {
   const navigate = useNavigate();
   const location = useLocation();
   const { setAdminLayOutValue } = useContext(LayoutContext);
   const [expanded, setExpanded] = useState<boolean>(true);

   useEffect(() => {
      setCollapseSideBar(expanded);
   }, [expanded]);

   const routeClick = (route: string) => {
      navigate(`${route}`);
   };
   return (
      <aside className="bg-green-700 h-full z-10 relative">
         <nav className="h-screen flex flex-col border-r shadow-sm">
            <div className="pb-2 flex justify-between items-center">
               <TitlLogo
                  className={`overflow-hidden transition-all ${expanded ? 'w-64' : 'w-0'}`}
               />
               <IconButton onClick={() => setExpanded((curr) => !curr)}>
                  {expanded ? (
                     <ArrowBackIos sx={{ color: '#f2f6fc' }} />
                  ) : (
                     <ArrowForwardIos sx={{ color: '#f2f6fc' }} />
                  )}
               </IconButton>
            </div>
            <ul className="flex-1 px-3">
               {/*<SideBarItem*/}
               {/*   icon={<Dashboard sx={{ color: '#f2f6fc' }} />}*/}
               {/*   text="Dashboard"*/}
               {/*   active={location.pathname === ADMIN_DASHBOARD_ROUTE_PATH}*/}
               {/*   expanded={expanded}*/}
               {/*   onClick={() => routeClick(ADMIN_DASHBOARD_ROUTE_PATH)}*/}
               {/*/>*/}
               <SideBarItem
                  icon={<Home sx={{ color: '#f2f6fc' }} />}
                  text="Properties"
                  active={location.pathname === ADMIN_PROPERTIES_ROUTE_PATH}
                  expanded={expanded}
                  onClick={() => routeClick(ADMIN_PROPERTIES_ROUTE_PATH)}
               />
               <SideBarItem
                  icon={<Home sx={{ color: '#f2f6fc' }} />}
                  text="Client View"
                  active={location.pathname === ''}
                  expanded={expanded}
                  onClick={() =>
                     (() => {
                        navigate(DASHBOARD_ROUTE_PATH);
                        setAdminLayOutValue(false);
                     })()
                  }
               />
            </ul>
            <div
               className={`border-t flex p-3  overflow-hidden transition-all w-full bottom-0
               }`}
            >
               <Button
                  variant="contained"
                  startIcon={<Logout />}
                  onClick={() => window.location.replace(LOGIN_ROUTE_PATH)}
               >
                  Logout
               </Button>
            </div>
         </nav>
      </aside>
   );
};

export default SideBar;
