import React, { FunctionComponent } from 'react';

interface SideBarItemProps extends Pick<React.HTMLProps<HTMLButtonElement>, 'onClick'> {
   icon: JSX.Element;
   text?: string;
   active?: boolean;
   expanded: boolean;
}

const SideBarItem: FunctionComponent<SideBarItemProps> = ({
   icon,
   text,
   active,
   expanded,
   onClick,
}) => {
   return (
      <li
         className={`relative flex items-center py-2 px-3 my-1 font-medium rounded-md cursor-pointer transition-colors ${
            active ? 'bg-[#00733B]' : 'hover:bg-[#00733B]'
         }`}
         onClick={onClick as any}
      >
         {icon}
         <span
            className={`text-white ml-2 overflow-hidden transition-all ${
               expanded ? 'w-64' : 'w-0'
            }`}
         >
            {text}
         </span>
      </li>
   );
};

export default SideBarItem;
