import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
const initialState: any[] = [];

const transactionSlice = createSlice({
   name: 'transactions',
   initialState,
   reducers: {
      addTransactions: (state, action: PayloadAction<any[]>) => {
         return [...state, ...action.payload];
      },
   },
});

export const { addTransactions } = transactionSlice.actions;

const selectTransactions = (state: any) => state.transactions;
export const getTransactionByUuid = createSelector(
   selectTransactions,
   (_: any, uuid: string) => uuid,
   (transactions: any, uuid: string) =>
      transactions.filter((transaction: any) => transaction.uuid === uuid)[0],
);

export default transactionSlice.reducer;
