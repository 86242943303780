import { initializeApp } from 'firebase/app';
import { getAnalytics, logEvent } from 'firebase/analytics';
import { User } from '@titl-all/shared';
const env = import.meta?.env?.VITE_ENV || '';

const config = {
   apiKey: 'AIzaSyAchAKINhGCw8SkDkgXK6wlHgTgiU66D_k',
   authDomain: 'titl-98255.firebaseapp.com',
   projectId: 'titl-98255',
   storageBucket: 'titl-98255.appspot.com',
   messagingSenderId: '1082092697374',
   appId: '1:1082092697374:web:56504f54e891349625bb0f',
   measurementId: 'G-TJ8D823F7P',
};

// Initialize Firebase
export const app = initializeApp(
   env === 'production'
      ? {
           apiKey: 'AIzaSyAchAKINhGCw8SkDkgXK6wlHgTgiU66D_k',
           authDomain: 'titl-98255.firebaseapp.com',
           projectId: 'titl-98255',
           storageBucket: 'titl-98255.appspot.com',
           messagingSenderId: '1082092697374',
           appId: '1:1082092697374:web:abcaf69b2551708725bb0f',
           measurementId: 'G-CHL6SG00JX',
        }
      : config,
);
export const analytics = getAnalytics(app);

export const captureEvent = ({
   eventName = '',
   data = {},
}: {
   eventName: string;
   data: { user?: User; [key: string]: any };
}) => {
   // disable logging for localhost
   if (
      window?.location?.hostname?.includes('localhost') &&
      !window.location.search.includes('debug')
   ) {
      return;
   }

   const eventData = {} as { [key: string]: any };
   Object.keys(data).forEach((key) => {
      //convert data string since firebase doesn't support values that are non strings like objects and arrays
      if (
         typeof data[key] !== 'string' ||
         typeof data[key] !== 'number' ||
         typeof data[key] !== 'boolean'
      ) {
         eventData[key] = JSON.stringify(data[key]);
      } else {
         eventData[key] = data[key];
      }
   });
   // console.log('CAPTURING_EVENT', `${eventName}`, eventData);
   return logEvent(analytics, `${eventName}`, {
      ...eventData,
      debug: window.location.search.includes('debug'),
      env: env,
   });
};
