export const TENANTS_ROUTE_PATH = '/tenants';
export const PROPERTY_TENANTS_ROUTE_PATH = '/tenants/:id';
export const ADD_TENANT_ROUTE_PATH = '/tenant/create';
export const IMPORT_TENANT_ROUTE_PATH = '/tenant/import';
export const ADD_EXISTING_TENANT_ROUTE_PATH = '/tenant/existing/create';
export const ADD_NEW_TENANT_ROUTE_PATH = '/tenant/existing/:uuid/create';
export const TENANT_PROFILE_ROUTE_PATH = '/tenant/profile/:uuid/:tenantId';
export const EDIT_TENANT_PROFILE_ROUTE_PATH = '/tenant/profile/:tenantId/edit';
export const TENANT_AGREEMENT_ROUTE_PATH = '/tenant/agreement/:uuid';
export const TENANT_RENEW_AGREEMENT_ROUTE_PATH = '/tenant/agreement/:uuid/:action';

export const TENANT_DOCUMENTS_ROUTE_PATH = '/tenant/documents/:uuid/:title/:key';
