import { User } from '@titl-all/shared';

import { patch, post } from './fetcher';

interface Credentials {
   googleToken?: string;
   email?: string;
   phoneNumber?: string;
   password?: string;
   uuid?: string;
   manager?: {
      name: string;
      email: string;
      phoneNumber: string;
   } | null;
}

interface UpdatePasswordCredentials {
   password: string;
   passwordConfirmation?: string;
   code: number;
}

interface ExistsResponse {
   isUser?: boolean;
   isContact?: boolean;
   hasPassword: boolean;
   offline?: boolean;
   message?: string;
}

interface LoginResponse {
   user?: User;
   token?: string;
   message: string;
}

export async function updatePassword(credentials: UpdatePasswordCredentials): Promise<any> {
   return await patch<any>('/api/auth/update-password', credentials);
}

export async function logIn(credentials: Credentials): Promise<LoginResponse> {
   const response = await post<LoginResponse>('/api/v2/auth/login', credentials);
   if (response.data) {
      localStorage.setItem('access-token', response.data.token as string);
      // properly set cookie if not set from the backend
      document.cookie = `titl=${response.data.token}`;
   }
   return response.data;
}

export async function logOut(): Promise<void> {
   document.cookie = `titl=${localStorage.getItem('access-token')}; Expires=${new Date('1970')};`;
   localStorage.removeItem('access-token');
   await post('/api/auth/logout');
}

export async function signUp(credentials: Credentials): Promise<LoginResponse> {
   const response = await post<LoginResponse>('/api/v2/auth/signup', credentials);
   if (response.data) {
      localStorage.setItem('access-token', response.data.token as string);
      // properly set cookie if not set from the backend
      document.cookie = `titl=${response.data.token}`;
   }
   return response.data;
}

export async function userExists(email: string): Promise<ExistsResponse> {
   const response = await post<ExistsResponse>('/api/auth-checks/user-exists', { email });

   if (response.status === 400) {
      return {
         isUser: false,
         isContact: false,
         hasPassword: false,
         offline: false,
         message: 'Invalid email address. Try again',
      };
   } else {
      return response.data;
   }
}

export async function contactExists(phoneNumber: string): Promise<ExistsResponse> {
   const response = await post<ExistsResponse>('/api/auth-checks/contact-exists', {
      phoneNumber,
   });

   return response.data;
}

export async function sendOTP(identifier: string) {
   return await post('/api/auth/send-otp', {
      identifier,
   });
}
