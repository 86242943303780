import { ContextProviderType } from '@titl-all/shared';
import { createContext, Dispatch, FC, SetStateAction, useState } from 'react';

export const LayoutContext = createContext<{
   adminLayOutValue: boolean;
   setAdminLayOutValue: Dispatch<SetStateAction<boolean>>;
}>({
   adminLayOutValue: false,
   setAdminLayOutValue: () => undefined,
});

export const LayoutProvider: FC<ContextProviderType> = ({ children }: ContextProviderType) => {
   const [adminLayOutValue, setAdminLayOutValue] = useState<boolean>(false);
   return (
      <LayoutContext.Provider value={{ adminLayOutValue, setAdminLayOutValue }}>
         {children}
      </LayoutContext.Provider>
   );
};
