import { User } from '@titl-all/shared';
import { useContext, useEffect } from 'react';
import useSWR from 'swr';

import { fetcher } from '../api';
import { UNAUTHORIZED_CODES } from '../constants';
import { UserContext } from '../context';
import {
   FORGOT_PASSWORD_ROUTE_PATH,
   LOGIN_ROUTE_PATH,
   SIGNUP_MANAGER_ROUTE_PATH,
   SIGNUP_OWNER_ROUTE_PATH,
   SIGNUP_ROUTE_PATH,
   UPDATE_PASSWORD_ROUTE_PATH,
   VERIFY_CODE_ROUTE_PATH,
} from '../route-paths';

/*
 * Use User
 * @desc grab the user from the context of if the user is logged in,
 * grab the user from the API and set the context
 */
const useUser = (): User[] & { image?: string }[] => {
   const publicRoutes = [
      SIGNUP_MANAGER_ROUTE_PATH,
      SIGNUP_OWNER_ROUTE_PATH,
      SIGNUP_ROUTE_PATH,
      LOGIN_ROUTE_PATH,
      FORGOT_PASSWORD_ROUTE_PATH,
      VERIFY_CODE_ROUTE_PATH,
      UPDATE_PASSWORD_ROUTE_PATH,
   ];
   const { user: presetUser, setUser } = useContext(UserContext);

   const pathname = window.location.pathname;
   const outsideDashboard = publicRoutes.includes(pathname);

   const { data, error } = useSWR(
      presetUser || outsideDashboard ? null : '/api/user/current',
      fetcher,
   );

   if (error && UNAUTHORIZED_CODES.includes(error.status)) {
      window.location.replace(LOGIN_ROUTE_PATH);
   }

   const user = (data as User) || null;

   useEffect(() => {
      if (user && !presetUser) {
         setUser(user);
      }
   }, [user, setUser, presetUser]);

   return [presetUser || user];
};

export default useUser;
