import { DEFAULT_ADMIN_ROUTE_PATH } from './Dashboard';

/** client routes**/
export const PROPERTIES_ROUTE_PATH = '/properties';
export const ADD_PROPERTY_ROUTE_PATH = '/property/create';
export const EDIT_PROPERTY_ROUTE_PATH = '/property/:id/edit';
export const PROPERTY_UNITS_ROUTE_PATH = '/property/:id/units';
export const ALL_UNITS_ROUTE_PATH = '/property/units';
export const ADD_PROPERTY_UNIT_ROUTE_PATH = '/property/unit/create';
export const EDIT_PROPERTY_UNIT_ROUTE_PATH = '/property/unit/:unitId/edit';
export const PROPERTY_UNIT_PROFILE_ROUTE_PATH = '/property/unit/:unitId';
export const ADD_PROPERTY_UNITS_ROUTE_PATH = '/property/unit/:uuid/create';
export const IMPORT_PROPERTY_UNITS_ROUTE_PATH = '/property/units/:uuid/import';
export const PROPERTY_UNITS_IMAGES_UPLOAD_ROUTE_PATH = '/property/units/images/upload';

/** admin routes**/
export const ADMIN_PROPERTIES_ROUTE_PATH = `${DEFAULT_ADMIN_ROUTE_PATH}/properties`;
