import { ChevronLeft } from '@mui/icons-material';
import { Box, Container, IconButton } from '@mui/material';
import React, { FunctionComponent } from 'react';
import { useNavigate } from 'react-router-dom';

import { LOGIN_ROUTE_PATH } from '../route-paths';

interface UnAuthorizedLayoutProps {
   children: JSX.Element;
}

const UnAuthorizedLayout: FunctionComponent<UnAuthorizedLayoutProps> = ({ children }) => {
   const navigate = useNavigate();
   return (
      <Container maxWidth="xs">
         <Box className="flex flex-col p-5 my-24 text-center">
            <div className="flex items-center space-x-2">
               {![LOGIN_ROUTE_PATH].includes(window.location.pathname) && (
                  <>
                     <IconButton onClick={() => navigate(-1)} className="bg-black/10">
                        <ChevronLeft />
                     </IconButton>
                     <span>Back</span>
                  </>
               )}
            </div>
            {children}
         </Box>
      </Container>
   );
};

export default UnAuthorizedLayout;
