import { Notifications } from '@mui/icons-material';
import {
   Avatar,
   Box,
   Divider,
   Drawer,
   List,
   ListItem,
   ListItemButton,
   ListItemIcon,
   ListItemText,
   Typography,
} from '@mui/material';
import { FunctionComponent, useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { ReactComponent as Cancel } from '../assets/Cancel.svg';
import { ReactComponent as Home } from '../assets/Home.svg';
import { ReactComponent as Install } from '../assets/Install.svg';
import { ReactComponent as LogOut } from '../assets/Logout.svg';
import { ReactComponent as Permissions } from '../assets/Permissions.svg';
import { ReactComponent as Property } from '../assets/Properties.svg';
import { ReactComponent as Expenses } from '../assets/Requests.svg';
import { ReactComponent as Tenants } from '../assets/Tenants.svg';
import { LayoutContext } from '../context';
import { useUser } from '../hooks';
import useUserAdmin from '../hooks/useUserAdmin';
import {
   ADD_PROPERTY_ROUTE_PATH,
   ADMIN_DASHBOARD_ROUTE_PATH,
   NOTIFICATION_ROUTE_PATH,
   LOGIN_ROUTE_PATH,
   TENANTS_ROUTE_PATH,
} from '../route-paths';
import { stringAvatar } from '../utils/User';

interface INavigationDrawer {
   toggle: number;
}

const NavigationDrawer: FunctionComponent<INavigationDrawer> = ({ toggle }) => {
   const navigate = useNavigate();
   const [user] = useUser();
   const isUserAdmin = useUserAdmin();
   const { setAdminLayOutValue } = useContext(LayoutContext);
   const [toggleDrawer, setToggleDrawer] = useState<boolean>(false);
   const [promptEvent, setPromptEvent] = useState<Event | null>(null);

   useEffect(() => {
      const handleBeforeInstallPrompt = (event: Event) => {
         event.preventDefault();
         setPromptEvent(event);
      };
      window.addEventListener('beforeinstallprompt', handleBeforeInstallPrompt);
      return () => {
         window.removeEventListener('beforeinstallprompt', handleBeforeInstallPrompt);
      };
   }, []);

   const handleInstallClick = () => {
      if (promptEvent) {
         (promptEvent as any).prompt();
      }
   };

   const displayItems = [
      {
         text: 'Notifications',
         icon: <Notifications className="text-white" />,
         onclick: () => {
            navigate(NOTIFICATION_ROUTE_PATH);
         },
      },
      {
         text: 'Add Tenants',
         icon: <Tenants />,
         onclick: () => {
            navigate(TENANTS_ROUTE_PATH);
         },
      },
      {
         text: 'Add Property',
         icon: <Property />,
         onclick: () => {
            navigate(ADD_PROPERTY_ROUTE_PATH);
         },
      },
      {
         text: 'Add Expenses',
         icon: <Expenses />,
         onclick: () => {
            navigate('/');
         },
      },
      {
         text: 'Reports',
         icon: <Home />,
         onclick: () => {
            navigate('/');
         },
      },
      {
         text: 'Requests',
         icon: <Home />,
         onclick: () => {
            navigate('/');
         },
      },
      {
         text: 'Permissions and Access',
         icon: <Permissions />,
         onclick: () => {
            navigate('/');
         },
      },
      {
         text: 'Install web app',
         icon: <Install />,
         onclick: () => handleInstallClick(),
      },
      {
         text: 'Logout',
         icon: <LogOut />,
         onclick: async () => {
            window.location.replace(LOGIN_ROUTE_PATH);
         },
      },
   ];

   useEffect(() => {
      toggle > 0 && setToggleDrawer(true);
   }, [toggle]);

   const list = () => (
      <Box role="presentation" style={{ padding: '10px' }} className="max-w-[260px]">
         <div className="flex justify-end w-full p-4">
            <Cancel
               onClick={() => {
                  setToggleDrawer(false);
               }}
               style={{ cursor: 'pointer' }}
            />
         </div>
         <div className="flex items-center justify-between w-full p-4 space-x-4">
            <div className="flex-grow truncate text-ellipsis">
               <Typography className="font-bold text-white truncate text-ellipsis">
                  {user?.firstName} {user?.lastName}
               </Typography>
               <Typography className="text-xs text-gray-300 truncate text-ellipsis">
                  {user?.email}
               </Typography>
            </div>
            <Avatar
               className="w-14 h-14"
               {...stringAvatar(`${user?.firstName} ${user?.lastName}`, user?.image)}
            />
         </div>
         <List>
            {displayItems.slice(0, 3).map(({ text, icon, onclick }, index) => (
               <ListItem key={index} disablePadding>
                  <ListItemButton
                     onClick={() => {
                        onclick();
                        setToggleDrawer(false);
                     }}
                  >
                     <ListItemIcon>{icon}</ListItemIcon>
                     <ListItemText
                        primary={text}
                        className="[&>span]:font-bold [&>span]:text-sm text-[#C4D3D1]"
                     />
                  </ListItemButton>
               </ListItem>
            ))}
         </List>
         <Divider sx={{ borderStyle: 'dashed', backgroundColor: 'white', height: '1px' }} />
         <List>
            {[
               ...displayItems,
               (() => {
                  return isUserAdmin
                     ? {
                          text: 'Admin View',
                          icon: <Permissions />,
                          onclick: () => {
                             navigate(ADMIN_DASHBOARD_ROUTE_PATH);
                             setAdminLayOutValue(true);
                          },
                       }
                     : {};
               })(),
            ]
               .slice(6, 10)
               .map(({ text, icon, onclick }, index) => (
                  <ListItem key={index} disablePadding>
                     <ListItemButton
                        onClick={() => {
                           onclick && onclick();
                           setToggleDrawer(false);
                        }}
                     >
                        <ListItemIcon>{icon}</ListItemIcon>
                        <ListItemText
                           primary={text}
                           className="[&>span]:font-bold [&>span]:text-sm text-[#C4D3D1]"
                        />
                     </ListItemButton>
                  </ListItem>
               ))}
         </List>
      </Box>
   );
   return (
      <Drawer
         anchor="right"
         open={toggleDrawer}
         onClose={() => setToggleDrawer(false)}
         PaperProps={{
            sx: {
               backgroundColor: '#15803D',
               marginTop: '55px',
               marginBottom: '50px',
               borderRadius: '10px 0 0 10px',
               maxHeight: '80%',
            },
         }}
      >
         {list()}
      </Drawer>
   );
};

export default NavigationDrawer;
