import './index.css';
import 'react-toastify/dist/ReactToastify.css';

import { CssBaseline, StyledEngineProvider } from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';
import { GoogleOAuthProvider } from '@react-oauth/google';
import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';

import App from './App';
import { store } from './app/store';
import { defaultTheme } from './theme';

const clientId = import.meta.env.VITE_GOOGLE_OAUTH_CLIENT_ID;

ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
   <Provider store={store}>
      <React.StrictMode>
         <ThemeProvider theme={defaultTheme}>
            <StyledEngineProvider injectFirst>
               <GoogleOAuthProvider clientId={clientId}>
                  <CssBaseline />
                  <App />
               </GoogleOAuthProvider>
            </StyledEngineProvider>
         </ThemeProvider>
      </React.StrictMode>
   </Provider>,
);
