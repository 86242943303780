import { User } from '@titl-all/shared';
import React, { createContext } from 'react';

export const UserContext = createContext<{
   user?: User | null | undefined;
   setUser: React.Dispatch<React.SetStateAction<User | null | undefined>>;
   isSet: boolean;
   setIsSet: React.Dispatch<React.SetStateAction<boolean>>;
}>({
   user: null,
   setUser: () => undefined,
   isSet: false,
   setIsSet: () => undefined,
});
