import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const initialState: any[] = [];

const propertyUnitsSlice = createSlice({
   name: 'propertyUnits',
   initialState,
   reducers: {
      addUnitImages: (state, action: PayloadAction<any[]>) => {
         return [...state, ...action.payload];
      },
   },
});

export const { addUnitImages } = propertyUnitsSlice.actions;
export default propertyUnitsSlice.reducer;
