import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';

import { PropertyDetails } from '../../types';

const initialState: PropertyDetails[] = [];

const propertySlice = createSlice({
   name: 'properties',
   initialState,
   reducers: {
      addProperty: (state, action: PayloadAction<PropertyDetails[]>) => {
         return [...state, ...action.payload];
      },
   },
});

export const { addProperty } = propertySlice.actions;

const selectProperties = ({ properties }: { properties: PropertyDetails[] }) => properties;

export const getPropertyById = createSelector(
   selectProperties,
   (_: any, propertyId: string) => propertyId,
   (properties: PropertyDetails[], propertyId: string) =>
      properties.filter((property: PropertyDetails) => property.id === Number(propertyId))[0],
);

export default propertySlice.reducer;
