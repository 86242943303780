import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';

const initialState: any[] = [];

const invoiceSlice = createSlice({
   name: 'invoices',
   initialState,
   reducers: {
      addInvoices: (state, action: PayloadAction<any[]>) => {
         return [...state, ...action.payload];
      },
   },
});

export const { addInvoices } = invoiceSlice.actions;

const selectInvoices = (state: any) => state.invoices;

export const getInvoiceById = createSelector(
   selectInvoices,
   (_: any, uuid: string) => uuid,
   (invoices: any, uuid: string) => invoices.filter((invoice: any) => invoice.uuid === uuid)[0],
);
export default invoiceSlice.reducer;
