import { Action, configureStore, ThunkAction } from '@reduxjs/toolkit';

import invoiceReducer from './slices/invoiceSlice';
import propertiesReducer from './slices/propertySlice';
import propertyUnitsReducer from './slices/propertyUnitsSlice';
import transactionReducer from './slices/transactionSlice';

export const store = configureStore({
   reducer: {
      properties: propertiesReducer,
      propertyUnits: propertyUnitsReducer,
      transactions: transactionReducer,
      invoices: invoiceReducer,
   },
   middleware: (getDefaultMiddleware) => getDefaultMiddleware({ serializableCheck: false }),
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
   ReturnType,
   RootState,
   unknown,
   Action<string>
>;
