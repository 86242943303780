export const CREATE_PROPERTY_ENDPOINT_PATH = '/api/v2/properties';
export const GET_PROPERTIES_ENDPOINT_PATH = '/api/v2/properties';
export const EDIT_PROPERTY_ENDPOINT_PATH = '/api/v2/properties/:id';

export const GET_PROPERTIES_COUNT_ENDPOINT_PATH = '/api/v2/properties/count';

export const GET_PROPERTY_BY_ID_ENDPOINT_PATH = '/api/v2/properties/property/:id';

export const GET_PROPERTY_OCCUPANCY_PERCENTAGE_ENDPOINT_PATH =
   '/api/v2/occupancy/:propertyId/percentage';

export const GET_PROPERTY_OUTSTANDING_ENDPOINT_PATH =
   '/api/v2/reports/properties/:propertyId/outstanding';

export const GET_PROPERTY_RENT_INFORMATION_ENDPOINT_PATH =
   '/api/v2/reports/properties/:propertyId/rent-information';

export const GET_PROPERTY_UNIT_INFORMATION_ENDPOINT_PATH =
   '/api/v2/reports/properties/:propertyId/unit-information';

export const GET_PROPERTY_REPORT_ENDPOINT_PATH = '/api/v2/reports/properties/:propertyId';

/**Admin endpoints**/
export const GET_ADMIN_PROPERTIES_ENDPOINT_PATH = '/api/v2/properties/all';
export const GET_ADMIN_PROPERTIES_COUNT_ENDPOINT_PATH = '/api/v2/properties/all/count';
export const ADMIN_POST_PROPERTIES_ENDPOINT_PATH = '/api/v2/properties/:id';
