import { IconButton } from '@mui/material';
import React from 'react';
import { useNavigate } from 'react-router-dom';

import { ReactComponent as Back } from '../assets/Back.svg';
import { DASHBOARD_ROUTE_PATH } from '../route-paths';

interface HeaderProperties {
   greeting: string;
   children: JSX.Element;
}

const HeaderBar: React.FC<HeaderProperties> = ({ greeting, children }) => {
   const navigate = useNavigate();
   return (
      <div className="flex h-14 bg-green-950 justify-between items-center fixed w-full z-10">
         <div>
            {window.location.pathname !== DASHBOARD_ROUTE_PATH && (
               <IconButton onClick={() => navigate(-1)}>
                  <Back />
               </IconButton>
            )}
         </div>
         <div
            style={{
               height: '29px',
               fontWeight: '600',
               fontSize: '24px',
               lineHeight: '29px',
               color: '#777777',
            }}
            className="truncate whitespace-nowrap"
         >
            {greeting}
         </div>
         {children}
      </div>
   );
};

export default HeaderBar;
