import { Avatar, Grid, IconButton } from '@mui/material';
import React, { FC, useContext, useState } from 'react';

import { ReactComponent as Hamburger } from '../../assets/Hamburger.svg';
import { BottomBar, Drawer, HeaderBar, SideBar } from '../../components';
import { LayoutContext, MenuContext } from '../../context';

interface ILayout {
   children: JSX.Element;
}

const Layout: FC<ILayout> = ({ children }) => {
   const [toggleDrawer, setToggleDrawer] = useState<number>(0);
   const [collapseSideBar, setCollapseSideBar] = useState<boolean>(false);
   const { menuContent } = useContext(MenuContext);
   const { adminLayOutValue } = useContext(LayoutContext);

   return adminLayOutValue ? (
      <Grid container>
         <Grid item xs={12}>
            <HeaderBar greeting="">
               <Avatar />
            </HeaderBar>
         </Grid>
         <Grid container xs={12} item className="flex space-between">
            <div className={`overflow-hidden transition-all ${collapseSideBar ? 'w-1/6' : 'w-16'}`}>
               <SideBar setCollapseSideBar={setCollapseSideBar} />
            </div>
            <div className={`mt-16 px-4 ${collapseSideBar ? 'w-5/6' : 'w-11/12'}`}>{children}</div>
         </Grid>
      </Grid>
   ) : (
      <Grid container className="flex">
         <Grid item xs={12}>
            <HeaderBar greeting={menuContent}>
               <IconButton
                  onClick={() => {
                     setToggleDrawer(toggleDrawer + 1);
                  }}
               >
                  <Hamburger />
               </IconButton>
            </HeaderBar>
            <Drawer toggle={toggleDrawer} />
         </Grid>
         <Grid item xs={12} className="flex mt-16 px-4 lg:px-12">
            {children}
         </Grid>
         <Grid item xs={12} className="mt-24">
            <BottomBar />
         </Grid>
      </Grid>
   );
};

export default Layout;
