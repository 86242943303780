import { FC, useEffect, useRef } from 'react';
import { BrowserRouter, useLocation } from 'react-router-dom';
import { SWRConfig } from 'swr';

import { fetcher } from './api';
import { UNAUTHORIZED_CODES } from './constants';
import { LayoutProvider, MenuContextProvider, UserContextProvider } from './context';
import { LOGIN_ROUTE_PATH } from './route-paths';
import RoutePaths from './Routes';
import { captureEvent } from './firebase';

const Layout = () => {
   const location = useLocation();
   const locationRef = useRef('');

   useEffect(() => {
      if (locationRef?.current !== location.pathname) {
         locationRef.current = location.pathname;
         const page_path = location.pathname + location.search;
         locationRef.current = location.pathname;
         captureEvent({ eventName: 'screen_view', data: { page_path } });
      }
   }, [location, locationRef]);

   return <RoutePaths />;
};
const App: FC = () => {
   return (
      <BrowserRouter>
         <UserContextProvider>
            <MenuContextProvider>
               <LayoutProvider>
                  <SWRConfig
                     value={{
                        fetcher,
                        onError: (error) => {
                           if (
                              error &&
                              (UNAUTHORIZED_CODES.includes(error.status) ||
                                 error.data.code === 'invalid_request')
                           ) {
                              window.location.replace(LOGIN_ROUTE_PATH);
                           }
                        },
                     }}
                  >
                     <Layout />
                  </SWRConfig>
               </LayoutProvider>
            </MenuContextProvider>
         </UserContextProvider>
      </BrowserRouter>
   );
};

export default App;
